import {DataFrame as DFD} from "data-forge";

export interface DataFrame {
    schema: any[],
    records: any[][],
}

export const df2dfd = (df: DataFrame) => {
    let dfd = new DFD({values: df.records})
    if(df.records.length===0){
        dfd = new DFD({values: [df.schema.map((v)=>undefined)]})
    }
    const rename = dfd.getColumnNames().reduce((obj, k, i) => ({...obj, [k]: df.schema[i] }), {})
    return dfd.renameSeries(rename)
}

export const dfd2df = (dfd: DFD | any): DataFrame=> {
    return {
        schema: dfd.getColumnNames(),
        records: dfd.toRows()
    }
}
