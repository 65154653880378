import { api } from "../helpers"


const loadClientPagination = (page: number, pageSize: number, brokerName: string | null) => {
    if (brokerName) {
        brokerName = `&brokerName=${brokerName}`
    }
    else {
        brokerName = ""
    }
    return api.get(`/clients?page=${page}&pageSize=${pageSize}${brokerName}`)
}

const loadClientDetail = (idClient: string) => {
    return api.get(`/clients/${idClient}`)
}

const loadMangement = (brokerName: string, page: number, pageSize: number, filterBy: any, sortBy: any) => {
    const body = {brokerName, page, pageSize, filterBy, sortBy}
    return api.post(`/clients/management/management`, body)
}

const loadPortfolio = (brokerName: string, filterBy: any) => {
    const body = {brokerName, filterBy}
    return api.post(`/clients/management/portfolio`, body)
}


export const clientService = {
    loadClientPagination,
    loadClientDetail,
    loadMangement,
    loadPortfolio
}
