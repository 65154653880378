import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../stores'
import { useDispatch } from 'react-redux'
import { loadClientDetail } from '../../../stores/clients/actions'
import { Pagination, Table } from '../../../components'
import { useParams } from 'react-router-dom'

export const ClientDetail = () => {
    let { id } = useParams() as any
    const dispatch = useDispatch()
    const limitExpand = 20
    const [isExpandExpectedPNL, setIsExpandExpectedPNL] = useState(false)
    const [isExpandRealisedPNL, setIsExpandRealisedPNL] = useState(false)
    const [isExpandDeposite, setIsExpandDeposite] = useState(false)
    // const [isExpandPortfolio, setIsExpandPortfolio] = useState(false)
    // const [isExpandAssets, setIsExpandAssets] = useState(false)
    const expectedPNL = useSelector((state: AppState) => state.clientDetail.detail?.data?.expectedPNL)
    const realisedPNL = useSelector((state: AppState) => state.clientDetail.detail?.data?.realisedPNL)
    const deposite = useSelector((state: AppState) => state.clientDetail.detail?.data?.deposite)
    const portfolio = useSelector((state: AppState) => state.clientDetail.detail?.data?.portfolio)    
    const assets = useSelector((state: AppState) => state.clientDetail.detail?.data?.assets)
    const filterData = (data: any, isExpand: boolean, includeLastRow: boolean) => {
        if (data){
            const newData = Object.assign({}, data)
            newData.records = isExpand ? data.records : data.records.slice(0, Math.min(limitExpand-1, data.records.length - 1))
            if (includeLastRow && !isExpand) {
                newData.records.push(data.records.at(-1))
            }
            return newData
        }
        return data
    }
    useEffect(() => {
        if (id) loadClientDetail(id)(dispatch)
        const intervalId = setInterval(() => {
            if (id) loadClientDetail(id)(dispatch)
        }, id ? 1000 * 5 : 0)
        return () => clearInterval(intervalId)
    }, [dispatch, id])
    return (
        < div className="container-fluid" >
            {/* Page Heading */}
            < h1 className="h3 mb-4 text-dark" >{`Báo cáo khách hàng ${id}`}</h1 >
            <div className="row">
                <div className="col-lg">
                    <h1 className="h4 mb-2 text-dark">BÁO CÁO TÀI SẢN</h1>
                    <div className="card shadow mb-4">
                        <div className="card-body">
                            {<Table data={assets} hightLightLastRow={false} firstSticky={false} sortElements={[]}></Table>}
                        </div>
                        <div className='card-footer'>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <h1 className="h4 mb-2 text-dark">THEO DÕI NỘP CỌC</h1>
                    <div className="card shadow mb-4">
                        <div className="card-body">
                            <Table data={filterData(deposite, isExpandDeposite, true)} hightLightLastRow={true} firstSticky={false} sortElements={[]}></Table>
                        </div>
                        {(deposite?.records && deposite.records.length > limitExpand ? <div role='button' onClick={()=>{setIsExpandDeposite(!isExpandDeposite)}}><u>{(isExpandDeposite ? "thu gọn" : "xem toàn bộ")}</u></div> : <div/>)}
                        <div className='card-footer'>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8">
                    <h1 className="h4 mb-2 text-dark">DANH MỤC CỔ PHIẾU HIỆN TẠI</h1>
                    <div className="card shadow mb-4">
                        <div className="card-body">
                            <Table data={portfolio} hightLightLastRow={true} firstSticky={false} sortElements={[]}></Table>
                        </div>
                        <div className='card-footer'>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg">
                    <h1 className="h4 mb-2 text-dark">DANH MỤC LỢI NHUẬN KỲ VỌNG</h1>
                    <div className="card shadow mb-4">
                        <div className="card-body">
                            <Table data={filterData(expectedPNL, isExpandExpectedPNL, true)} hightLightLastRow={true} firstSticky={false} sortElements={[]}></Table>
                        </div>
                        {(expectedPNL?.records.length && expectedPNL.records.length > limitExpand ? <div role='button' onClick={()=>{setIsExpandExpectedPNL(!isExpandExpectedPNL)}}><u>{(isExpandExpectedPNL ? "thu gọn" : "xem toàn bộ")}</u></div> : <div/>)}
                        <div className='card-footer'>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg">
                    <h1 className="h4 mb-2 text-dark">DANH MỤC LỢI NHUẬN ĐÃ TẤT TOÁN</h1>
                    <div className="card shadow mb-4">
                        <div className="card-body">
                            <Table data={filterData(realisedPNL, isExpandRealisedPNL, true)} hightLightLastRow={true} firstSticky={false} sortElements={[]}></Table>
                        </div>
                        {(realisedPNL?.records && realisedPNL.records.length > limitExpand ? <div role='button' onClick={()=>{setIsExpandRealisedPNL(!isExpandRealisedPNL)}}><u>{(isExpandRealisedPNL ? "thu gọn" : "xem toàn bộ")}</u></div> : <div/>)}
                        <div className='card-footer'>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

