import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../stores'
import { useDispatch } from 'react-redux'
import { loadUserPagination } from '../../../stores/users/actions'
import { Pagination } from '../../../components'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import $ from 'jquery'

export const ListUser = () => {
    const filterColumns = ["nameBroker", "email"]
    const sortColumns = ["nameBroker", "email"]
    const [filterBy, setFilterBy] = useState<any>({})
    const [filterByDelay, setFilterByDelay] = useState<any>({})
    const [sortElements, setSortElements] = useState<any>([])
    const [sortByState, setSortByState] = useState<any>({ email: "ASC", nameBroker: "" })
    const users = useSelector((state: AppState) => state.user.pagination.data)
    const total = useSelector((state: AppState) => state.user.pagination.total)
    const currentPage = useSelector((state: AppState) => state.user.pagination.page + 1)
    const pageSize = useSelector((state: AppState) => state.user.pagination.pageSize)

    const onPageChanged = (page: number) => {
        loadUserPagination(page - 1, pageSize, getFilters(), Object.keys(sortByState).filter((key: any) => (sortByState[key] !== "")).map((key: any) => ({ field: key, direction: sortByState[key] })))(dispatch)
    }

    const dispatch = useDispatch()

    let navigate = useNavigate();
    const userElements = users?.map((user) => {
        return (
            <tr>
                <td>{user.id}</td>
                <td>{user.nameBroker}</td>
                <td><Link className='text-dark' to={`/admin/users/${user.id}`}>{user.email}</Link></td>
                <td>{user.password}</td>
                <td>{user.role}</td>
                <td>{user.adminNameBroker}</td>
            </tr>
        )
    })

    const getFilters = () => {
        const filterBy = {} as any
        for (const col of filterColumns) {
            const value = $(`#${col}Input`).val() as string;
            filterBy[col] = value === "" ? undefined : value
        }
        return filterBy
    }

    const onChangeFilter = (e: any) => {
        setFilterBy(getFilters())
    }
    const onChangeOrder = (fieldName: string) => {
        const func = () => {
            let value = sortByState[fieldName]
            if (value === "") {
                value = "DESC"
            } else if (value === "DESC") {
                value = "ASC"
            } else {
                value = ""
            }
            setSortByState({ ...sortByState, [fieldName]: value })
        }
        return func
    }

    useEffect(() => {
        loadUserPagination(currentPage - 1, pageSize, getFilters(), Object.keys(sortByState).filter((key: any) => (sortByState[key] !== "")).map((key: any) => ({ field: key, direction: sortByState[key] })))(dispatch)
    }, [dispatch, currentPage, filterByDelay, sortByState])
    useEffect(() => {
        const timeOutId = setTimeout(() => { setFilterByDelay(filterBy) }, 1000);
        return () => clearTimeout(timeOutId);
    }, [filterBy])
    return (
        <>
            <div>
                <h1 className="h3 mb-2 text-dark">Danh sách người dùng</h1>
                {/* DataTales Example */}
                <div className="card shadow mb-4">
                    {/* <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">PNL</h6>
                    </div> */}
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing={0}>
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th className={sortByState.nameBroker === "" ? "sorting" : (sortByState.nameBroker === "ASC" ? "sorting sorting_asc" : "sorting sorting_desc")} onClick={onChangeOrder("nameBroker")}>nameBroker</th>
                                        <th className={sortByState.email === "" ? "sorting" : (sortByState.email === "ASC" ? "sorting sorting_asc" : "sorting sorting_desc")} onClick={onChangeOrder("email")}>email</th>
                                        <th>password</th>
                                        <th>role</th>
                                        <th>adminNameBroker</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>id</th>
                                        <th>nameBroker</th>
                                        <th>email</th>
                                        <th>password</th>
                                        <th>role</th>
                                        <th>adminNameBroker</th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td><input type="text" className="form-control form-control-sm" placeholder={"search"} defaultValue={undefined} onChange={onChangeFilter} id={`nameBrokerInput`} /></td>
                                        <td><input type="text" className="form-control form-control-sm" placeholder={"search"} defaultValue={undefined} onChange={onChangeFilter} id={`emailInput`} /></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    {userElements}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='card-footer'>
                        <Pagination total={total} currentPage={currentPage} pageSize={pageSize} pageLimit={5} onPageChanged={onPageChanged} />
                    </div>
                </div>
            </div>
        </>
    )
}

