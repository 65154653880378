import { api } from "../helpers"


const get_all = () => {
    return api.get("/brokers")
}

export const brokerService = {
    get_all,
}
