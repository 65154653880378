import { api } from "../helpers";

const loadCwValuation = (
  page: number,
  pageSize: number,
  filterBy: any,
  sortBy: any,
) => {
  const body = { page, pageSize, filterBy, sortBy };
  return api.post(`/cwValuation`, body);
};

export const cwValuationService = {
  loadCwValuation,
};
