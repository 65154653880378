import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { userService, brokerService } from '../../../services'
import $ from 'jquery'
import { Button, Modal } from 'react-bootstrap'
export const UserEdit = () => {
    let { userID } = useParams() as any
    const [brokers, setBrokers] = useState<any[]>([])
    const [user, setUser] = useState<any>([])
    const defaultErrors = {
        idClient: null as any,
    }
    const [errors, setErrors] = useState(defaultErrors)
    const [showModal, setShowModal] = useState(false);
    
    useEffect(() => {
        userService.loadUserByID(userID).then(
            (res) => { setUser(res.data.data) },
            (error) => {
                if(error.data.errors?.email){
                    alert("Người dùng không tồn tại")
                }
            }
        )
        brokerService.get_all().then(
            (res) => { setBrokers(res.data.data) },
            (error) => { }
        )
    }, [])
    const dropDownElements = brokers?.map((broker) => {
        return (
            <option value="" selected={broker.nameBroker == user.adminNameBroker}>{broker.nameBroker}</option>
        )
    })
    const editUserClick = async () => {
        let adminBrokerName = $('#inputAdminBrokerName').find(":selected").text() as string | null;
        adminBrokerName = adminBrokerName === "" ? null : adminBrokerName
        console.log(adminBrokerName)
        await userService.editUser(user.id, adminBrokerName).then(
            (res) => { alert("chỉnh sửa người dùng thành công"); window.location.reload() },
            (error) => { alert("unknown error, contact for support"); setErrors(error.data?.errors) }
        )
    }
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const handleConfirmModal = () => {
        console.log(user)
        userService.deleteUser(user.id).then(
            (res) => { alert("xóa người dùng thành công"); window.location.reload() },
            (error) => { alert("unknown error, contact for support"); setErrors(error.data?.errors) }
        )
    };

    return (
        <>
            <div>
                <br />
                <br />
                <br />
                <div className="container">
                    <div className="row">
                        <h3 className="col-lg-4 text-dark text-left">
                            Tên người dùng:
                        </h3>
                        <h3 className="col-lg-4 text-dark text-left">
                            {user.email}
                        </h3>
                    </div>
                    <div className="row">
                        <h3 className="col-lg-4 text-dark text-left">
                            Admin Broker Name:
                        </h3>
                        <h3 className="col-lg-4 text-dark text-left">
                            <select className="form-control" name="features" id="inputAdminBrokerName">
                                <option value=""></option>
                                {dropDownElements}
                            </select>
                        </h3>
                    </div>
                    <br />
                    <div className="row">
                        <button type="button" className="btn btn-success" onClick={editUserClick}>Lưu chỉnh sửa người dùng</button>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="row">
                        <Button variant="danger" onClick={handleShowModal}>
                            Xóa người dùng
                        </Button>

                        <Modal show={showModal} onHide={handleCloseModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Xác nhận xóa người dùng:</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{user.email}</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseModal}>
                                    Huỷ bỏ
                                </Button>
                                <Button variant="primary" onClick={handleConfirmModal}>
                                    Xác nhận
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                {/* <div className="row"> */}
                {/* </div> */}
            </div>
        </>
    )
}

