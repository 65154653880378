import React, { useEffect, useState } from "react";
import { Pagination, Table } from "../../../components";
import { df2dfd, dfd2df } from "../../../helpers";
import $ from "jquery";
import { cwValuationService } from "../../../services";

export const CwValuation = () => {
  const filterColumns = ["ticker", "cwUnderlying", "cwIssuerName", "status"];
  const sortColumns = [
    "averageGrowthRatePerSession",
    "annualizedOpportunityCost",
    "primiumDiscountRatioMarkToSellingPrice",
  ];
  const [cwValuationRes, setCwValuationRes] = useState<Record<any, any>>({});
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  const [filterBy, setFilterBy] = useState<any>({});
  const [filterByDelay, setFilterByDelay] = useState<any>({});
  const [sortByState, setSortByState] = useState<any>({
    averageGrowthRatePerSession: "",
    annualizedOpportunityCost: "",
    primiumDiscountRatioMarkToSellingPrice: "",
  });
  const [sortElements, setSortElements] = useState<any>([]);
  const getFilters = () => {
    const filterBy = {} as any;
    for (const col of filterColumns) {
      const value = $(`#${col}Input`).val() as string;
      filterBy[col] = value === "" ? undefined : value;
    }
    return filterBy;
  };

  const onChangeFilter = (e: any) => {
    setFilterBy(getFilters());
  };

  const onChangeOrder = (fieldName: string) => {
    const func = () => {
      let value = sortByState[fieldName];
      if (value === "") {
        value = "DESC";
      } else if (value === "DESC") {
        value = "ASC";
      } else {
        value = "";
      }
      setSortByState({ ...sortByState, [fieldName]: value });
    };
    return func;
  };

  const loadCwValuation = async (
    page: number,
    _pageSize: number,
    filterBy: any,
    sortBy: any,
  ) => {
    await cwValuationService
      .loadCwValuation(page - 1, _pageSize, filterBy, sortBy)
      .then(
        (res) => {
          var dfd = df2dfd(res.data.data);
          // dfd = dfd.generateSeries({
          //     depositRate: (row) => <span style={{ color: (row.depositRate >= 0.2 ? "green" : "red") }}>{(row.depositRate * 100).toLocaleString() + "%"}</span>
          // })
          // dfd = dfd.generateSeries({
          //     updatedAt: (row) => <span className="text-nowrap">{row.updatedAt}</span>,
          //     nameClient: (row) => <span className="text-nowrap">{row.nameClient}</span>,
          // })
          const map = {
            ticker: "Mã CK",
            bid1: "Dư mua 1",
            lastPrice: "Giá",
            ask1: "Dư bán 1",
            cwUnderlying: "CK Cơ sở: Mã CK",
            cwUnderlyingLastPrice: "CK Cơ sở: Giá",
            cwUnderlyingBid1: "CK Cơ sở: Dư mua 1",
            cwUnderlyingAsk1: "CK Cơ sở: Dư bán 1",
            cwIssuerName: "Tổ chức phát hành",
            cwType: "Loại CW",
            cwLastTradingDate: "Ngày GDCC",
            cwExcersisePrice: "Giá thực hiện",
            cwExerciseRatio: "Tỷ lệ chuyển đổi",
            breakevenPrice: "Giá hòa vốn CW",
            breakevenPriceMinusMarketPrice: "ĐHV - Giá TT",
            latestBreakevenSellingPrice: "Giá hòa vốn bán gần nhất",
            latestBreakevenBuyingPrice: "Giá hòa vốn mua gần nhất",
            primiumDiscountRatioMarkToSellingPrice:
              "Tỷ lệ primium/Discount theo giá bán gần nhất",
            dayLeftUntilMaturityDate: "Thời gian còn đến ngày đáo hạn",
            tradingDayLeftUntilMaturityDate: "Số phiên giao dịch còn lại",
            annualizedOpportunityCost: "Chi phí cơ hội quy năm",
            averageGrowthRatePerSession: "Tốc độ tăng bình quân phiên",
            status: "Trạng thái",
            __updatedDate__: "Ngày/Giờ cập nhật",
          } as any;
          // const roundCols = {} as any;
          // Object.keys(map).filter((key: any) => {
          //   if (
          //     !["idClient", "nameClient", "depositRate", "updatedAt"].includes(
          //       key,
          //     )
          //   ) {
          //     roundCols[key] = (row: any) =>
          //       Number(row[key]) === row[key] ? Math.round(row[key]) : row[key];
          //   }
          // });
          // dfd = dfd.generateSeries(roundCols);
          dfd = dfd.subset(Object.keys(map));
          const filterRows = [];
          const sortElements = [];
          for (const col of dfd.getColumnNames()) {
            if (sortColumns.includes(col)) {
              sortElements.push({
                field: col,
                state: sortByState,
                onClick: onChangeOrder(col),
              });
            } else {
              sortElements.push(undefined);
            }
            if (filterColumns.includes(col)) {
              const placeholder = "search";
              const defaultValue = undefined;
              filterRows.push(
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder={placeholder}
                  defaultValue={defaultValue}
                  onChange={onChangeFilter}
                  id={`${col}Input`}
                />,
              );
            } else {
              filterRows.push(undefined);
            }
          }
          setSortElements(sortElements);
          // dfd = dfd.generateSeries({
          //   idClient: (row) =>
          //     row.idClient !== "Tổng" ? (
          //       <Link className="text-dark" to={`/clients/${row.idClient}`}>
          //         {row.idClient}
          //       </Link>
          //     ) : (
          //       row.idClient
          //     ),
          // });
          dfd = dfd.renameSeries(map);
          // <Link className='text-dark' to={`/admin/users/${user.id}`}>{user.email}</Link>
          res.data.data = dfd2df(dfd);
          res.data.data.records = [filterRows].concat(res.data.data.records);
          setCwValuationRes(res);
          setTotal(res.data.total);
        },
        (error) => {},
      );
  };

  const onPageChanged = (page: number) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    loadCwValuation(
      currentPage,
      pageSize,
      getFilters(),
      Object.keys(sortByState)
        .filter((key: any) => sortByState[key] !== "")
        .map((key: any) => ({ field: key, direction: sortByState[key] })),
    );
    const intervalId = setInterval(() => {
      loadCwValuation(
        currentPage,
        pageSize,
        getFilters(),
        Object.keys(sortByState)
          .filter((key: any) => sortByState[key] !== "")
          .map((key: any) => ({ field: key, direction: sortByState[key] })),
      );
    }, 5000);
    return () => clearInterval(intervalId);
  }, [filterByDelay, currentPage, sortByState]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setFilterByDelay(filterBy);
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [filterBy]);

  return (
    <div className="container-fluid">
      {/* Page Heading */}
      <h1 className="h3 mb-4 text-dark">{`Định giá chứng quyền`}</h1>
      <div className="row">
        <div className="col-lg">
          <h1 className="h4 mb-2 text-black-50"></h1>
          <div className="card shadow mb-4">
            <div className="card-body">
              {cwValuationRes.data?.data ? (
                <Table
                  data={cwValuationRes.data.data}
                  hightLightLastRow={true}
                  firstSticky={true}
                  sortElements={sortElements}
                ></Table>
              ) : (
                <></>
              )}
            </div>
            <div className="card-footer">
              <Pagination
                total={total}
                currentPage={currentPage}
                pageSize={pageSize}
                pageLimit={5}
                onPageChanged={onPageChanged}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
